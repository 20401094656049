import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import { advancedRoadmapsPlanPageInitialChecksResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { advancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary';
import { softwarePlanSummaryEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/ui/embed';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type PlanSummaryType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPlanSummaryEmbed = lazyForPaint<typeof PlanSummaryType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-summary-embed", jiraSpaEntry: "async-plan-summary-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary'
			),
		),
	{ ssr: false },
);

const PlanSummaryEmbedPage = () => (
	<LazyPage Page={LazyPlanSummaryEmbed} pageId="plan-summary-embed" shouldShowSpinner />
);

export const arjSummaryEmbedRouteEntry: Route = createEntry(softwarePlanSummaryEmbedRoute, {
	component: componentWithFG('smart_links_for_plans', PlanSummaryEmbedPage, ErrorPagesNotFound),

	layout: createLayout({
		isChromeless: true,
	}),

	resources: [advancedRoadmapsPlanPageInitialChecksResource, advancedRoadmapsSummaryDataResource],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	forPaint: [LazyPlanSummaryEmbed],
});
